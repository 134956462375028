import React, { useState } from 'react'

// graphQL
import { getGraphComicsCards } from '../../../graphQL'

// Components
import ComicCard from '../../../components/atoms/ComicCard'

// Style
import {
  comicsTitle,
  comicsWrapper,
  comicsDescription,
} from './style.module.scss'

const AllComics: React.FC = () => {
  const allComics = getGraphComicsCards()

  const [sorted, setSorted] = useState(
    allComics.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    )
  )

  return (
    <>
      <h1 className={comicsTitle}>Comics</h1>
      <h3 className={comicsDescription}>
        Aquí puedes ver todos los cómics registrados en nuestra página, en orden
        alfabético.
      </h3>
      <div className={comicsWrapper}>
        {sorted.map((c) => (
          <ComicCard comic={c} key={c.id} />
        ))}
      </div>
    </>
  )
}

export default AllComics
